/**
 * Mocking client-server processing
 */
const _projects = [
  {
    id: 24,
    logo: "./img/logos/brisker_logo.png",
    klant: "Brisker group",
    projectnaam: "IVY",
    rol: "Senior .net webdeveloper",
    start: "Jan 2019",
    eind: "",
    duur: "",
    situatie:
      "De Brisker group is marktleider op het gebied van payroll. Het stond begin 2019 voor een groot aantal uitdagingen. Zo had het een aantal bedrijven overgenomen die ge�ntegreerd moesten worden in het platform. De wet WAB maakte dat de dienstverlening aangepast moest worden met mogelijk ingrijpende gevolgen voor het platform. Verder was het platform verouderd (ms access) en moest dit opnieuw opgebouwd worden (project Ivy). " +
      "\r\nHoewel ik formeel op het Project Ivy zat, had ik hier een speciale rol. Ik was niet bezig met het een-op-een overzetten van functionaliteit maar pakte de onderdelen op die wat meer aandacht nodig hadden. ",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 23,
    logo: "./img/logos/kpmg.png",
    klant: "KPMG / Rabobank",
    projectnaam: "Rentederivaten",
    rol: "Senior .net webdeveloper",
    start: "Mrt 2017",
    eind: "Jan 2019",
    duur: "1jr 10mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 22,
    logo: "./img/logos/Greenchoice.svg",
    klant: "Greenchoice",
    projectnaam: "",
    rol: "Lead .net webdeveloper / Architect",
    start: "Aug 2014",
    eind: "Mrt 2017",
    duur: "2jr 9mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 21,
    logo: "./img/logos/Parkmobile.png",
    klant: "ParkMobile",
    projectnaam: "",
    rol: "Senior .net webdeveloper",
    start: "Jan 2013",
    eind: "Aug 2014",
    duur: "1jr 7mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 20,
    logo: "./img/logos/Fiserv.png",
    klant: "Fiserv",
    projectnaam: "",
    rol: "Senior .net webdeveloper",
    start: "Aug 2011",
    eind: "Jan 2013",
    duur: "1jr 5mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 19,
    logo: "./img/logos/InMotiv.png",
    klant: "InMotiv",
    projectnaam: "",
    rol: "Senior .net webdeveloper",
    start: "Apr 2011",
    eind: "Aug 2011",
    duur: "3 mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 18,
    logo: "./img/logos/Centric.png",
    klant: "Centric",
    projectnaam: "",
    rol: "Senior .net webdeveloper",
    start: "Nov 2008",
    eind: "Apr 2011",
    duur: "2jr 5mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 17,
    logo: "./img/logos/ing_real.png",
    klant: "ING real estate",
    projectnaam: "",
    rol: "Lead .net webdeveloper",
    start: "Sep 2008",
    eind: "Nov 2008",
    duur: "3 mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 16,
    logo: "./img/logos/interpolis.png",
    klant: "Interpolis",
    projectnaam: "",
    rol: "Lead .net webdeveloper",
    start: "Jul 2007",
    eind: "Sep 2008",
    duur: "1jr 1mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 15,
    logo: "./img/logos/NC3A.png",
    klant: "NAVO",
    projectnaam: "Cosine",
    rol: "Senior .net webdeveloper",
    start: "Okt 2006",
    eind: "Jul 2007",
    duur: "10 mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 14,
    logo: "./img/logos/rechtspraak.png",
    klant: "Bistro",
    projectnaam: "",
    rol: "Senior .net webdeveloper",
    start: "Aug 2006",
    eind: "Okt 2006",
    duur: "3mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 13,
    logo: "./img/logos/logica-cmg.png",
    klant: "CMG",
    projectnaam: "UBC",
    rol: "Onderzoeker",
    start: "Feb 2006",
    eind: "Okt 2006",
    duur: "10mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 12,
    logo: "./img/logos/amsterdam.png",
    klant: "Gem. Amsterdam",
    projectnaam: "",
    rol: "Senior .net webdeveloper",
    start: "Feb 2006",
    eind: "Aug 2006",
    duur: "7mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 11,
    logo: "./img/logos/cmg.png",
    klant: "CMG",
    projectnaam: "My Benefits",
    rol: "Senior .net webdeveloper",
    start: "Jan 2005",
    eind: "Feb 2006",
    duur: "1jr 1mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 10,
    logo: "./img/logos/cmg.png",
    klant: "CMG",
    projectnaam: "",
    rol: "Architect / senior developer",
    start: "Mrt 2003",
    eind: "Jan 2005",
    duur: "2jr 4mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 9,
    logo: "./img/logos/achmea.jpg",
    klant: "Achmea",
    projectnaam: "",
    rol: "Senior webdeveloper",
    start: "Apr 2001",
    eind: "Mrt 2003",
    duur: "1jr 10mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 8,
    logo: "./img/logos/Aegon.png",
    klant: "Aegon",
    projectnaam: "",
    rol: "Webdeveloper",
    start: "Nov 2000",
    eind: "Apr 2001",
    duur: "1jr 5mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 7,
    logo: "./img/logos/Aegon.png",
    klant: "Aegon",
    projectnaam: "",
    rol: "VB developer",
    start: "Mei 2000",
    eind: "Nov 2000",
    duur: "7mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 6,
    logo: "./img/logos/Aegon.png",
    klant: "Aegon",
    projectnaam: "",
    rol: "Analist",
    start: "Feb 2000",
    eind: "Mei 2000",
    duur: "4mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 5,
    logo: "./img/logos/cmg.png",
    klant: "web developer",
    projectnaam: "",
    rol: "Webdeveloper",
    start: "Jan 2000",
    eind: "Feb 2000",
    duur: "2mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 4,
    logo: "./img/logos/Aon.png",
    klant: "Aon",
    projectnaam: "",
    rol: "MS Access ontwikkelaar",
    start: "Aug 1998",
    eind: "Jan 2000",
    duur: "1jr 6mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 3,
    logo: "./img/logos/kpmg.png",
    klant: "KPMG",
    projectnaam: "",
    rol: "MS Access ontwikkelaar",
    start: "Jul 1996",
    eind: "Aug 1998",
    duur: "1jr 1mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 2,
    logo: "./img/logos/abn-amro.png",
    klant: "ABN-AMRO",
    projectnaam: "Afstudeer opdracht",
    rol: "Afstudeer opdracht",
    start: "Jan 1996",
    eind: "Jun 1996",
    duur: "5 mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  },
  {
    id: 1,
    logo: "./img/logos/ichtus.png",
    klant: "Ichthus hogeschool",
    projectnaam: "",
    rol: "Studen assistent",
    start: "Aug 1995",
    eind: "",
    duur: "10 mnd",
    situatie: "",
    methodiek: "SCRUM",
    tools: "Visual studio 2019, Azure DevOps, TurtoiseGit, SSMS",
    database: "SQL server 2014"
  }
]

export default {
  getProjects(cb) {
    setTimeout(() => cb(_projects), 100)
  }
}