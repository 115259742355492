<template>
  <div class="content">
    <div class="md-layout" style="padding-top:100px">
      <div class="md-layout-item md-medium-size-100 md-size-90" style="margin: 0 auto;">
         <div>{{project.klant}}</div>
        <project-card button-color="success" v-bind:project="this.byId(1)"> </project-card>
      </div>
    </div>
  </div>
</template>

<script>
   import {ProjectCard} from "@/pages";

   import { createNamespacedHelpers } from 'vuex'
   const { mapGetters } = createNamespacedHelpers('projects')

   export default {
      components: {
         ProjectCard
      },
      props: {
         // id: this.$route.params.id,
         projectold: { klant: 'test' },
         //project: this.byId(this.$route.params.id)
      },

      computed: {
         ...mapGetters(['byId'])
		}
      /*
      computed: {
         project: this.$store.getters.byId(this.$route.params.id)
      },
      /*
      computed: mapState({
         project: state => state.projects.all()this.$route.params.id
         store.getters.byId(this.$route.params.id)
      }),
      */
   };
   //const project = this.byId(this.$route.params.id);
</script>

<style scoped>
</style>