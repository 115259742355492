import Vue from 'vue'
import Vuex from 'vuex'

import projects from './modules/Projects'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    projects
  },
})