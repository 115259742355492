import projectsService from '../../api/projectsService'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  byId: (state) => (id) => {
    state.all.find(project => project.id === id)
  }
}

// actions
const actions = {
  getAllProjects({ commit }) {
    projectsService.getProjects(projects => {
      commit('setProjects', projects)
    })
  },
}

// mutations
const mutations = {
  setProjects(state, projects) {
    state.all = projects
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}