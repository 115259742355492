<template>  
   <div class="container">
    <div class="md-layout" style="padding-top:100px; margin: 0 auto">
      <div class="md-layout-item md-medium-size-100 md-size-90">

          <md-table v-model="projects" style="width:100%">
            <md-table-row slot="md-table-row" slot-scope="{ item }" @click="onClick(item)">
              <md-table-cell md-label="">
                <div>
                  <img style="height:25px" :src="item.logo" />
                </div>
              </md-table-cell>
              <md-table-cell md-label="Klant">{{ item.klant }}</md-table-cell>
              <md-table-cell md-label="Rol">{{ item.rol }}</md-table-cell>
              <md-table-cell md-label="Start"><div style="text-align:right;">{{ item.start }}</div></md-table-cell>
              <md-table-cell md-label="Duur"><div style="text-align:right;">{{ item.duur }}</div></md-table-cell>
            </md-table-row>
          </md-table>

      </div>
    </div>
  </div>
</template>

<script>
   import {mapState} from 'vuex'

   export default {
      name: "Projects",
      computed: mapState({
         projects: state => state.projects.all
      }),
      methods: {
         onClick(item) {
            //this.$router.push({ path: '/project/' + item.id })
         }
      },
      created() {
         this.$store.dispatch('projects/getAllProjects')
      }
};
</script>

<style scoped>
</style>