<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <img class="img" :src="cardUserImage" />
    </div>
    <md-card-content>
      <div>{{ project.klant }}</div>
      <md-button :class="getColorButton(buttonColor)" href="../files/CV Xander Bommelé.pdf">Download CV</md-button>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "project-card",
  props: {
    cardUserImage: {
      type: String,
      default: "./img/faces/xander.jpg"
    },
    buttonColor: {
      type: String,
      default: ""
    },
    project: { type: Object }
  },
  methods: {
    getColorButton: function(buttonColor) {
      return "md-round md-" + buttonColor + "";
    }
  }
};
</script>
<style></style>
