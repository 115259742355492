<template>  
   <div class="content">
    <div class="md-layout" style="padding-top:100px">
      <div class="md-layout-item md-medium-size-100 md-size-90" style="margin: 0 auto;">
      </div>
    </div>
  </div>
</template>

<script>
	export default {
      name: "Skills",
	};
</script>

<style scoped>
</style>